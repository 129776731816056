import * as Sentry from '@sentry/nextjs';

import {
  DragEvent,
  PropsWithChildren,
  createContext,
  useContext,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import { http } from '@hl-portals/libs/http';

import {
  FETCH_PRESIGNED_S3_LINK,
  PROCESS_AND_CREATE_FILE,
} from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

import { useBrowserEvent } from '@hl-portals/hooks';

import { Box, BoxTypes } from '../Box';
import { Icon } from '../Icon';
import Spinner from '../Spinner';
import { Paragraph } from '../Typography';

// =============================================================
// Types

type PresignedLink = { url: string; key: string };
type PresignedLinkResponse = PresignedLink[];

type ProcessAndCreateFilesResponse = {
  id: string;
  attributes: {
    name: string;
    created_at: string;
    updated_at: string;
  };
  type: string;
};

type FileStatus = 'idle' | 'uploading' | 'success' | 'error';
type FileType = 'pdf' | 'png' | 'jpeg' | 'jpg' | 'doc' | 'docx' | 'xml';

export type UploadFile = {
  id: string;
  file: File;
  key: string | null;
  status: FileStatus;
  progress: number;
  errors: string[];
};

// =============================================================
// Validators

const validateFileType = (file: File, supportedTypes: string[]) => {
  const type = file.name.split('.')[1];
  return supportedTypes.includes(type.toLowerCase());
};

const validateFileSize = (file: File, maxSize: number) => {
  return file.size <= maxSize;
};

const validateFileUniquess = (files: UploadFile[], file: File) => {
  if (files.length === 0) return true;

  return !files.some((f) => {
    return f.file.name === file.name && f.file.size === file.size;
  });
};

const validateMaxFiles = (filesLength: number, maxFiles: number) => {
  return filesLength <= maxFiles;
};

// =============================================================
// Errors

export const ERRORS = {
  TYPE: 'Invalid type',
  SIZE: 'File is too big',
  MAX_LENGTH: 'Too many files',
  UNIQUENESS: 'Already exists',
  INTERNAL: 'Unable to upload file',
};

// =============================================================
// Use Upload Hook

export type UseUploadOptions = {
  leadId?: string;
  token?: string;
  fileType?: 'document' | 'image';
  fileCategory?: 'bbys_document' | 'property_photo';
  source?: string;
  validate?: (file: File) => string | null;
  unique?: boolean;
  maxFileSize?: number;
  minFiles?: number;
  maxFiles?: number;
  accept?: FileType[];
  enableProcessAndCreate?: boolean;
  onSuccess?: (uploadedFiles: UploadFile[]) => void;
  onError?: (failedFiles: UploadFile[]) => void;
};

const defaultOptions: Partial<UseUploadOptions> = {
  fileType: 'document',
  fileCategory: 'bbys_document',
  source: 'questionnaire',
  unique: true,
  maxFileSize: undefined,
  minFiles: undefined,
  maxFiles: undefined,
  accept: ['pdf', 'doc', 'docx', 'xml', 'jpeg', 'jpg', 'png'],
  enableProcessAndCreate: false,
};

export const useUpload = (
  options: UseUploadOptions = {} as UseUploadOptions
) => {
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const _options = { ...defaultOptions, ...options };

  const validateFile = (file: File) => {
    const { name } = file;
    const errors: string[] = [];

    const hasValidType = _options?.accept
      ? validateFileType(file, _options?.accept)
      : true;

    if (!hasValidType) {
      errors.push(ERRORS.TYPE);
      toast('Please upload only .pdf, .doc, .docx, and .xml files', {
        type: 'error',
        position: 'top-right',
        autoClose: 3000,
      });
      return errors;
    }

    const hasValidSize = _options?.maxFileSize
      ? validateFileSize(file, _options?.maxFileSize)
      : true;
    if (!hasValidSize) {
      errors.push(ERRORS.SIZE);
      toast('Your file is larger than 10 MB', {
        type: 'error',
        position: 'top-right',
        autoClose: 3000,
      });
      return errors;
    }

    const hasValidMaxFilesLength = _options?.maxFiles
      ? validateMaxFiles(files.length, _options?.maxFiles)
      : true;
    if (!hasValidMaxFilesLength) {
      errors.push(name, ERRORS.MAX_LENGTH);
      return errors;
    }

    const hasValidUniqueness = _options?.unique
      ? validateFileUniquess(files, file)
      : true;

    if (!hasValidUniqueness) {
      errors.push(ERRORS.UNIQUENESS);
      return errors;
    }

    if (_options?.validate) {
      const error = _options.validate(file);
      if (error) errors.push(error);
    }

    return errors;
  };

  const add = (file: File) => {
    const timestamp = Date.now();
    const errors = validateFile(file);
    const status = errors.length > 0 ? 'error' : 'idle';

    setFiles((_files) => [
      ..._files,
      {
        id: `${file.name}-${timestamp}`,
        file,
        status,
        key: null,
        progress: 0,
        errors,
      },
    ]);
  };

  const remove = (id: string) => {
    setFiles((_files) => _files.filter((file) => file.id !== id));
    toast('Document removed', {
      type: 'success',
      position: 'top-right',
      autoClose: 3000,
    });
  };

  const update = (id: string, payload: Partial<UploadFile>) => {
    setFiles((prev) =>
      prev.map((f) => {
        if (f.id === id) return { ...f, ...payload };
        return f;
      })
    );
  };

  const setError = (id: string, error: keyof typeof ERRORS) => {
    setFiles((prev) =>
      prev.map((f) => {
        if (f.id === id) {
          return {
            ...f,
            status: 'error',
            errors: [...f.errors, ERRORS[error]],
          };
        }
        return f;
      })
    );
  };

  const clearError = (id: string) => {
    update(id, { status: 'idle', errors: [] });
  };

  const uploadToS3 = async (_files: UploadFile[]) => {
    const successful = [];
    const failed = [];

    for await (const file of _files) {
      const { id, errors } = file;

      try {
        if (!errors?.length) {
          update(id, { status: 'uploading' });

          const presignedLinkResponse =
            await http.public.post<PresignedLinkResponse>(
              FETCH_PRESIGNED_S3_LINK,
              {
                mime: file.file.type,
                filename: file.file.name,
                file_count: 1,
                file_type: _options?.fileType,
                source: _options?.source,
                attachable_id: _options?.leadId,
                token: _options?.token,
                documentType: 'bbys_ir_contract',
              }
            );

          const { url, key } = presignedLinkResponse.data?.[0] || {};

          await http.public.put(url, file.file, {
            onUploadProgress: (event) =>
              update(file.id, {
                progress: (event.loaded * 100) / (event.total || 1),
              }),
          });

          update(id, { key, status: 'success' });
          successful.push({ ...file, key });
        }
      } catch (error) {
        Sentry.captureException(error);
        setError(id, 'INTERNAL');
        failed.push(file);
        toast('Upload incomplete. Please check your files and re-upload', {
          type: 'error',
          position: 'top-right',
          autoClose: 3000,
        });
      }
    }

    return { successful, failed };
  };

  const processAndCreate = async (_files: UploadFile[]) => {
    try {
      await enhancedAxios<ProcessAndCreateFilesResponse>({
        url: PROCESS_AND_CREATE_FILE,
        method: 'POST',
        data: {
          include: 'file_versions',
          fields: {
            file_version: 'mime,fastly_url,storage_key',
          },
          attachable_id: _options?.leadId,
          attachable_type: 'Lead',
          category: _options?.fileCategory,
          files: _files.map(({ key, file }) => ({
            name: file.name,
            storage_key: key,
            file_type: _options?.fileType,
          })),
          token: _options?.token,
        },
      });

      return { success: true };
    } catch (error) {
      Sentry.captureException(error);
      toast('There was an error', {
        type: 'error',
        position: 'top-right',
        autoClose: 3000,
      });
      return { success: false };
    } finally {
      setIsLoading(false);
    }
  };

  const submit = async () => {
    const idleFiles = files.filter((f) => f.status === 'idle');

    if (idleFiles.length === 0) return { success: true };

    setIsLoading(true);
    const { successful, failed } = await uploadToS3(idleFiles);

    if (_options?.enableProcessAndCreate && successful.length > 0) {
      return processAndCreate(successful);
    }

    setIsLoading(false);
    return { success: failed.length === 0 };
  };

  return {
    files,
    isLoading,
    add,
    remove,
    update,
    setError,
    clearError,
    submit,
  };
};

export type UseUploadReturn = ReturnType<typeof useUpload>;

// =============================================================
// Root

const UploadContext = createContext<UseUploadReturn | undefined>(undefined);

export const useUploadContext = () => {
  const context = useContext(UploadContext);
  if (context === undefined) {
    throw new Error('useUploadContext must be used within a UploadProvider');
  }
  return context;
};

type RootProps = PropsWithChildren<UseUploadReturn>;

const Root = (props: RootProps) => {
  return (
    <UploadContext.Provider value={props}>
      {props.children}
    </UploadContext.Provider>
  );
};

type DropProps = BoxTypes & {
  disabled?: boolean;
  maxFileSize?: string; // in bytes
  accept?: string; // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
};

const Drop = (props: DropProps) => {
  const input = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  const { add } = useUploadContext();

  useBrowserEvent('OPEN_FILE_PICKER', () => {
    input.current?.click?.();
  });

  const onAddFiles = (files: FileList | null) => {
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (file) add(file);
      }
    }
  };

  const events = props.disabled
    ? {}
    : {
        onClick: () => input.current?.click?.(),
        onDragOver: (e: DragEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDragging(true);
        },
        onDragLeave: (e: DragEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDragging(false);
        },
        onDrop: (e: DragEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDragging(false);
          onAddFiles(e.dataTransfer.files);
        },
      };

  return (
    <Box
      width="100%"
      flex="1"
      p="24px"
      borderRadius="12px"
      border="2px dashed #C5C8CD"
      bgcolor={isDragging ? '#F2F8FE' : 'white'}
      transition="all 200ms ease-in-out"
      cursor={props.disabled ? 'not-allowed' : 'cursor'}
      opacity={props.disabled ? '.5' : '1'}
      {...events}
      {...props}
    >
      <input
        style={{ display: 'none' }}
        type="file"
        multiple
        accept={props.accept ?? ''}
        ref={input}
        onChange={(e) => onAddFiles(e.target.files)}
      />
      {props.children || (
        <Box flexDirection="column" alignItems="center" gap="20px">
          <Icon type="cloudUpload" />
          <Paragraph>Drag & drop or</Paragraph>
          <Paragraph color="#1192E5" cursor="pointer">
            Select files
          </Paragraph>
          {props.maxFileSize && (
            <Paragraph color="coolGray2">
              Max {props.maxFileSize} MB each
            </Paragraph>
          )}
        </Box>
      )}
    </Box>
  );
};

// =============================================================
// List

const List = () => {
  const { files, remove } = useUploadContext();

  const renderIcon = (status: UploadFile['status']) => {
    switch (status) {
      case 'uploading':
        return <Spinner md />;
      case 'error':
        return <Icon type="exclamationCircleError" />;
      default:
        return <Icon type="file" />;
    }
  };

  return (
    <Box flexDirection="column" gap="8px">
      {files.map((file, i) => {
        const { id, file: innerFile, status, errors } = file;
        const { name: fileName, size } = innerFile;
        const [name, extension] = fileName.split('.');

        return (
          <Box
            key={`${id}-${i}`}
            p="16px"
            flexDirection="column"
            position="relative"
            border="1px solid"
            borderColor={status === 'error' ? '#F93A2F' : '#DBDFE6'}
            borderRadius="12px"
            overflowX="hidden"
            overflowY="hidden"
          >
            <Box justifyContent="space-between">
              <Box alignItems="center" gap="10px">
                <Box
                  width="24px"
                  height="24px"
                  justifyContent="center"
                  alignItems="center"
                >
                  {renderIcon(status)}
                </Box>
                <Paragraph variant="text-small" maxWidth="200px" truncate>
                  {name}
                </Paragraph>
              </Box>

              <Box alignItems="center" gap="16px">
                {status === 'error' ? (
                  errors.map((e) => (
                    <Paragraph variant="text-small" key={e} color="#F93A2F">
                      {e}
                    </Paragraph>
                  ))
                ) : (
                  <Paragraph variant="text-small" color="#72757D">
                    {Math.round(size / 1000)} KB {extension.toUpperCase()}
                  </Paragraph>
                )}

                {status !== 'success' && (
                  <Box
                    opacity=".1"
                    onClick={() => {
                      remove(id);
                    }}
                    cursor="pointer"
                  >
                    <Icon type="closeCircle" />
                  </Box>
                )}
              </Box>
            </Box>

            {status !== 'success' && (
              <Box
                width={file.progress + '%'}
                height="2px"
                position="absolute"
                bottom="0"
                left="0"
                bgcolor="#1192E5"
                transition="all 200ms ease-in-out"
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export const Upload = {
  Root,
  Drop,
  List,
};
